// <link rel="preload" href="/api/data" as="fetch" crossorigin="anonymous">
import Head from "next/head";
import Link from "next/link";
import { Card } from "react-bootstrap";
import StubWithAction from "../components/Stub/StubWithAction";
import PageLayout from "../components/_Layout/PageLayout";
import { supportEmail } from "../libs/constants";

export default function Custom404() {
  return (
    <PageLayout withHeader={false}>
      <main>
        <Head>
          <title>T-Buddy | Page could not be found</title>
        </Head>
        <StubWithAction
          type="404"
          title="Page could not be found."
          reason={
            <span>
              Return to <Link href="/">home page</Link>
            </span>
          }>
          <Card className="mx-auto" style={{ maxWidth: 350 }}>
            <Card.Body>
              <Card.Title>How you can help us?</Card.Title>
              <p>
                If you think that this page must exist write us on <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
              </p>
            </Card.Body>
          </Card>
        </StubWithAction>
      </main>
    </PageLayout>
  );
}
